import React from 'react';
import { sendToSlack } from '../components/Slack';
import { navigate } from 'gatsby';

const Page: React.FC = ({}) => {
  React.useEffect(() => {
    sendToSlack('早川写真:エスライフからアクセスがありました');
    navigate('/', {
      replace: true,
    });
  }, []);
  return <></>;
};
export default Page;
